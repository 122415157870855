<template>
  <div class="sponsor">
    <div class="title">{{ sponsroObj.title }}</div>
    <div class="sponsor-content">
      <a
        v-for="(item, index) in sponsorList"
        :key="index"
        :class="[item.class]"
        :href="item.url"
      ></a>
      <!-- <div></div> -->
    </div>
    <div class="sponsor-footer" @click="becomeSponsor">
      {{ sponsroObj.desc }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sponsroObj: {
      type: Object,
    },
  },
  data() {
    return {
      sponsorList: [
        {
          class: 'sponsor-1',
          url: 'https://tokenpocket.pro/',
        },
        {
          class: 'sponsor-2',
          url: 'https://swap.transit.finance',
        },
        {
          class: 'sponsor-3',
          url: 'https://www.did.id/',
        },
        {
          class: 'sponsor-15',
          url: 'https://iosg.vc/',
        },
        {
          class: 'sponsor-4',
          url: 'https://www.kucoin.com/kucoin-ventures',
        },
        {
          class: 'sponsor-5',
          url: 'http://bmlabs.io/',
        },
        {
          class: 'sponsor-6',
          url: 'http://waterdrip.io/',
        },
        {
          class: 'sponsor-7',
          url: 'https://hofan.cn/',
        },
        {
          class: 'sponsor-9',
          url: 'https://www.chaincatcher.com/',
        },
        {
          class: 'sponsor-16',
          url: 'https://www.4everland.org/',
        },
        {
          class: 'sponsor-8',
          url: 'https://tinyworlds.io/',
        },
        {
          class: 'sponsor-11',
          url: 'https://www.nftscan.com/',
        },
        {
          class: 'sponsor-10',
          url: 'https://ourspace.network/',
        },
        {
          class: 'sponsor-12',
          url: 'https://www.crypnote.io/',
        },
        {
          class: 'sponsor-13',
          url: 'https://genki.io/',
        },
        {
          class: 'sponsor-14',
          url: 'https://www.port3.io/',
        },
        {
          class: 'sponsor-17',
          url: 'https://gmatrixs.com/',
        },
        {
          class: 'sponsor-18',
          url: 'https://bitkeep.com/',
        },
      ],
    }
  },
  methods: {
    becomeSponsor() {
      window.open(this.sponsroObj.descUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.sponsor {
  width: 12rem;
  margin: 0 auto;
  padding: 0.52rem 0.23rem 0.58rem;
  border: 0.01rem solid #979797;
  border-radius: 0.36rem;
  .title {
    font-size: 0.3rem;
    font-family: Inter, Inter-Bold;
    font-weight: 700;
    text-align: center;
    color: #333333;
    line-height: 0.68rem;
  }
  .sponsor-content {
    margin-top: 0.68rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    // :last-child {
    //   width: 2.18rem;
    //   height: 0;
    // }
  }
  .sponsor-footer {
    margin-top: 0.4rem;
    font-size: 0.16rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #999999;
    line-height: 0.22rem;
    cursor: pointer;
  }
  .sponsor-footer:hover {
    opacity: 0.7;
  }
}
@media screen and (min-width: 767px) {
  @for $i from 1 through 18 {
    .sponsor-#{$i} {
      width: 2.18rem;
      height: 0.68rem;
      background: url(../assets/sponsors/#{$i}.png) no-repeat;
      background-size: contain;
    }
    .sponsor-#{$i}:hover {
      background: url(../assets/sponsors/#{$i}-on.png) no-repeat;
      background-size: contain;
    }
  }
}
@media screen and(max-width:767px) {
  @for $i from 1 through 18 {
    .sponsor-#{$i} {
      width: 1.96rem;
      height: 0.61rem;
      background: url(../assets/sponsors/#{$i}.png) no-repeat;
      background-size: contain;
    }
    .sponsor-#{$i}:hover {
      background: url(../assets/sponsors/#{$i}.png) no-repeat;
      background-size: contain;
    }
  }
  .sponsor {
    width: calc(100vw - 0.64rem);
    padding: 0.5rem 0.3rem 0.46rem;
    .title {
      font-size: 0.32rem;
      font-weight: 700;
      line-height: 0.45rem;
    }
    .sponsor-content {
      justify-content: space-between;
      margin-top: 0.5rem;
    }
    .sponsor-footer {
      margin-top: 0.3rem;
      font-size: 0.24rem;
      font-weight: 500;
      text-align: center;
      color: #999999;
      line-height: 0.33rem;
      cursor: pointer;
    }
    .sponsor-footer:hover {
      opacity: 1;
    }
  }
}
</style>
