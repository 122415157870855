<template>
  <div class="projects">
    <div class="projects-content">
      <div class="text title">{{ projects.title }}</div>
      <div class="text desc">
        {{ projects.desc }}
      </div>
      <div class="project-item-content">
        <div
          class="project-item"
          v-for="(item, index) in projects.list"
          :key="index"
          @click="goProject(item.url)"
        >
          <div class="bg-content">
            <img src="../assets/common/red-bg.png" class="red-bg" />
            <img src="../assets/common/green-bg.png" class="green-bg" />
          </div>
          <div class="project-item-info">
            <div class="item-header">
              <img class="title-img" :src="item.image" />
              <div class="text item-name">{{ item.title }}</div>
              <div class="text item-desc">{{ item.desc }}</div>
            </div>
            <div class="enter-button">
              <div class="text enter" :class="{ greyBtn: !item.url }">
                {{ item.buttonText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goProject(url) {
      if (url) {
        window.open(url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.projects {
  padding-bottom: 1.88rem;
  .text {
    font-size: 0.2rem;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: center;
    color: #9ea0a5;
  }
  .projects-content {
    width: 12rem;
    margin: 0 auto;
    .title {
      padding-top: 1.88rem;
      font-size: 0.72rem;
      font-family: Inter, Inter-Bold;
      font-weight: 700;
      color: #000000;
      letter-spacing: 0.01rem;
    }
    .desc {
      margin-top: 0.32rem;
      margin-bottom: 1rem;
      font-size: 0.24rem;
    }
    .project-item-content {
      display: flex;
      flex-wrap: wrap;
      .project-item {
        width: 3.72rem;
        display: flex;
        flex-direction: column;
        position: relative;
        // cursor: pointer;
        &.grey {
          opacity: 0.85;
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: gray;
          filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        }

        .bg-content {
          position: absolute;
          display: none;
          .red-bg {
            position: absolute;
            width: 3.72rem;
            height: 4.74rem;
          }
          .green-bg {
            position: absolute;
            transition: rotate(0) 0.5s;
            width: 3.72rem;
            height: 4.74rem;
          }
        }

        .project-item-info {
          z-index: 999;
          width: 3.72rem;
          height: inherit;
          padding: 0.3rem;
          background: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .item-header {
            .item-name {
              width: 100%;
              font-size: 0.24rem;
              font-family: Inter, Inter-Semi Bold;
              font-weight: Semi Bold;
              text-align: left;
              color: #3a3b3d;
            }
            .item-desc {
              // min-height: 0.8rem;
              // max-height: 0.8rem;
              // line-height: 0.2rem;
              margin-top: 0.1rem;
              font-size: 0.14rem;
              font-family: Inter, Inter-Regular;
              font-weight: 400;
              text-align: left;
              color: #9ea0a5;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
            }
            .title-img {
              width: 2.36rem;
              height: 2.16rem;
            }
          }
        }
        .enter-button {
          width: 100%;
          margin-top: 0.2rem;
          display: flex;
          align-items: center;
          .enter {
            width: 1.3rem;
            height: 0.44rem;
            line-height: 0.44rem;
            background: #2980fe;
            font-size: 0.16rem;
            font-family: Inter, Inter-Regular;
            font-weight: 400;
            text-align: center;
            color: #fff;
            border-radius: 0.04rem;
            &.greyBtn {
              background: #d1d1d1;
            }
          }
          img {
            margin-left: 0.1rem;
            width: 0.24rem;
            height: 0.24rem;
          }
        }
        .button {
          margin-top: 0.48rem;
          width: 1.88rem;
          height: 0.56rem;
          line-height: 0.56rem;
          background: #2980fe;
          border-radius: 0.08rem;
          font-size: 0.16rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .projects {
    @keyframes rotateani {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(5deg);
      }
    }
    @keyframes rotateani2 {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(-5deg);
      }
    }
    .projects-content {
      .project-item-content {
        .project-item {
          &:nth-of-type(n + 4) {
            margin-top: 0.5rem;
          }
          &:not(:nth-child(3n + 1)) {
            margin-left: 0.42rem;
          }
          .project-item-info {
            .item-header {
              .item-desc {
                min-height: 0.8rem;
                max-height: 0.8rem;
                line-height: 0.2rem;
              }
            }
          }
        }
        .project-item:hover {
          .bg-content {
            display: block;
            .red-bg {
              animation: 0.5s rotateani;
              transform: rotate(5deg);
            }
            .green-bg {
              animation: 0.5s rotateani2;
              transform: rotate(-5deg);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .projects {
    width: 100vw;
    padding: 0 0.56rem 1rem 0.56rem;
    .projects-content {
      width: 100%;
      .title {
        font-size: 0.56rem;
      }
      .desc {
        font-size: 0.26rem;
      }
      .project-item-content {
        .project-item {
          width: 100%;
          .project-item-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .item-header {
              display: flex;
              flex-direction: column;
              align-items: center;
              .item-name {
                font-size: 0.48rem;
                font-family: MiSans, MiSans-Semibold;
                font-weight: 700;
                text-align: center;
                color: #3a3b3d;
              }
              .item-desc {
                height: auto;
                font-size: 0.2rem;
                font-family: MiSans, MiSans-Regular;
                font-weight: 400;
                text-align: center;
                color: #9ea0a5;
              }
            }
            .enter-button {
              .enter {
                width: 2.5rem;
                height: 0.6rem;
                line-height: 0.6rem;
                font-size: 0.3rem;
                margin: 0 auto;
              }
              img {
                width: 0.3rem;
                height: 0.3rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
