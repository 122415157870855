<template>
  <div class="header">
    <img
      src="../assets/common/five-degrees.png"
      class="logo"
      @click="goRoute('/')"
    />
    <div class="right">
      <div class="d-flex ai-center developer" @click="goRoute('/developer')">
        <img src="../assets/developer/mint.gif" />
        <span class="text">{{ messages.developerText }}</span>
      </div>
      <span class="text" @click="openUrl(messages.whiteListUrl)">
        {{ messages.whiteList }}
      </span>
      <span class="text" @click="openUrl(messages.documentUrl)">
        {{ messages.document }}
      </span>
      <span class="text" @click="onViewPosition('.projects')">
        {{ messages.projectsText }}
      </span>
      <span class="text" @click="onViewPosition('.introduce')">
        {{ messages.whitePaper }}
      </span>
      <div class="text lang-button" @click="changeLanguage">
        {{ languageText }}
      </div>
    </div>
    <img
      src="../assets/mobile/menu.png"
      class="menu"
      ref="languageBtn"
      @click="showLanguageList"
    />
    <div class="lang-box" v-show="isShowLanguageBox">
      <div
        class="lang-text-box"
        v-for="(navItem, index) in menuList"
        :key="index"
        @click="onNavItem(navItem)"
      >
        <div class="menu-item-title">
          <img v-if="navItem.isMint" src="../assets/developer/mint.gif" />
          <div>{{ navItem.title }}</div>
          <img
            class="arrow-down"
            :class="{ active: navItem.isShow }"
            v-if="navItem.children"
            src="../assets/mobile/arrow-down.png"
          />
        </div>
        <div class="lang-list-box" v-if="navItem.isShow">
          <div
            class="lang-text-box lang-list"
            v-for="(langItem, index) in navItem.children"
            :key="index"
            @click="onChangeLang(langItem.lang)"
          >
            <div class="text">{{ langItem.langText }}</div>
            <div :class="{ line: index !== navItem.children.length - 1 }"></div>
          </div>
        </div>
        <div
          :class="{ line: index !== menuList.length - 1 && !navItem.isShow }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const whiteListEnUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSe9ZlN454NHvIxJ3mjHwXmS_ln6gIpopIgaYAE7qQyKCpPTHw/viewform'
const whiteListCnUrl = 'https://www.wenjuan.com/s/UZBZJvKuyP/'

export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowLanguageBox: false,
      enMenuList: [
        {
          title: 'Grant',
          route: '/developer',
          isMint: true,
        },
        {
          title: 'Whitelist',
          url: whiteListEnUrl,
        },
        {
          title: 'Language',
          lang: true,
          isShow: false,
          children: [
            { langText: '中文', lang: 'zh' },
            { langText: 'English', lang: 'en' },
          ],
        },
      ],
      cnMenuList: [
        {
          title: 'Grant',
          route: '/developer',
          isMint: true,
        },
        {
          title: '白名单',
          url: whiteListCnUrl,
        },
        {
          title: '语言',
          isShow: false,
          lang: true,
          children: [
            { langText: '中文', lang: 'zh' },
            { langText: 'English', lang: 'en' },
          ],
        },
      ],
    }
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener('click', this.onOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onOutside)
  },
  computed: {
    ...mapGetters(['language']),
    languageText() {
      if (this.language === 'en') {
        return '中文'
      } else {
        return 'English'
      }
    },
    menuList() {
      if (this.language === 'en') {
        return this.enMenuList
      } else {
        return this.cnMenuList
      }
    },
  },

  methods: {
    goRoute(path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    onViewPosition(val) {
      console.log(this.language)
      this.$emit('goViewPosition', val)
      if (this.$route.path !== '/') {
        this.$router.push({
          name: 'Home',
          params: {
            goProject: val,
          },
        })
      }
    },
    openUrl(url) {
      window.open(url)
    },
    showLanguageList() {
      this.isShowLanguageBox = !this.isShowLanguageBox
      this.enMenuList.forEach(item => {
        item.isShow = false
      });
      this.cnMenuList.forEach(item => {
        item.isShow = false
      });
    },
    onOutside(e) {
      const languageBtn = this.$refs.languageBtn
      if (languageBtn && !languageBtn.contains(e.target)) {
        const contentWrap = document.querySelector('.lang-box')
        if (contentWrap) {
          if (!contentWrap.contains(e.target)) {
            this.isShowLanguageBox = false
          }
        }
      }
    },
    onNavItem(navItem) {
      if (navItem.url) {
        window.open(navItem.url)
      } else if (navItem.children) {
        navItem.isShow = !navItem.isShow
      } else if (navItem.route) {
        this.goRoute(navItem.route)
      }
    },
    changeLanguage() {
      let lang = ''
      if (this.language === 'en') {
        lang = 'zh'
      } else {
        lang = 'en'
      }
      this.onChangeLang(lang)
    },
    onChangeLang(lang) {
      this.$store.commit('changeLanguage', lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 12rem;
  height: 0.72rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    font-size: 0.24rem;
    font-family: Inter, Inter-Black;
    font-weight: 900;
    text-align: center;
    color: #3a3b3d;
  }
  .right {
    display: flex;
    align-items: center;

    .developer {
      cursor: pointer;
      img {
        width: 0.24rem;
        height: 0.24rem;
      }
      span {
        margin-left: 0.1rem;
      }
    }

    span {
      font-size: 0.16rem;
      font-family: Poppins, Poppins-SemiBold;
      font-weight: SemiBold;
      color: #333333;
      cursor: pointer;
    }
    .lang-button {
      width: 1.06rem;
      height: 0.32rem;
      line-height: 0.32rem;
      border: 0.01rem solid #979797;
      border-radius: 0.17rem;
      font-size: 0.14rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #333333;
      cursor: pointer;
    }
    :nth-child(n + 2) {
      margin-left: 0.5rem;
    }
  }
}
@media screen and (min-width: 767px) {
  .header {
    .logo {
      width: 1.35rem;
      cursor: pointer;
    }
    .menu {
      display: none;
    }
    .lang-box {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .header {
    width: 100vw;
    height: 1.2rem;
    padding: 0 0.32rem;
    .logo {
      width: 2rem;
    }
    .left {
      font-size: 0.4rem;
    }
    .right {
      display: none;
    }
    .menu {
      display: block;
      width: 0.7rem;
      height: 0.7rem;
    }
    .lang-box {
      position: absolute;
      z-index: 1110;
      top: 1.2rem;
      left: 0;
      width: 100vw;
      background: #eee;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-right: 0.1rem;
        width: 0.3rem;
        height: 0.3rem;
      }
      .lang-text-box {
        width: 100vw;
        // margin: 0 auto;
        color: #333;
        font-size: 0.3rem;
        // height: 1rem;
        line-height: 1rem;
        // border-bottom: 0.02rem solid #f7f5ff;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .menu-item-title {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .arrow-down {
          transform-origin: center center;
          transform: rotate(-90deg);
          width: 0.3rem;
          height: 0.3rem;
          &.active {
            transform: rotate(0);
          }
        }
        .lang-list-box {
          width: 100vw;
          // position: absolute;
          background: #f7f5ff;
          // top: 2rem;
          .lang-list {
            text-align: center;
            // border-bottom: 0.02rem solid #fff;
          }
        }
      }
      .line {
        width: 95vw;
        margin: 0 auto;
        height: 0.01rem;
        background: #fff;
      }
    }
  }
}
</style>
