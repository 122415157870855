<template>
  <div class="developer">
    <Header :messages="messages" />
    <div class="developer-content">
      <div class="developer-header">
        <div class="common-content developer-header-wrap d-flex">
          <div class="left-wrap">
            <div class="text title">{{ messages.developer.header.title }}</div>
            <div class="text desc-mobile">
              <div class="text">
                {{ messages.developer.header.mobileDesc1 }}
              </div>
              <div class="text">
                {{ messages.developer.header.mobileDesc2 }}
              </div>
            </div>
            <div class="apply-wrap d-flex flex-column ai-center">
              <div
                class="text button"
                @click="openUrl(messages.developer.applyGrantUrl)"
              >
                {{ messages.developer.applyGrant }}
              </div>
              <div
                class="text view-documents"
                @click="openUrl(messages.developer.viewDocumentsUrl)"
              >
                {{ messages.developer.viewDocuments }}
              </div>
            </div>
          </div>
          <img class="developer-img" src="../assets/developer/developer.png" />
        </div>
      </div>
      <div class="banner">
        <div class="common-content banner-wrap" @click="onModal">
          <div class="prize">
            <img src="../assets/developer/prize.png" />
            <img src="../assets/developer/prize-btn.png" />
          </div>
          <div class="text prize-text">
            {{ messages.developer.banner.submit }}
          </div>
          <div class="chain-wrap d-flex ai-center">
            <div class="chain-icons d-flex ai-center jc-evenly">
              <img src="../assets/developer/bsc.png" />
              <img src="../assets/developer/eth.png" />
              <img src="../assets/developer/matic.png" />
              <img src="../assets/developer/okx.png" />
            </div>
            <img class="enter-grey" src="../assets/developer/enter-grey.png" />
          </div>
          <div class="desc-wrap">
            <div class="text desc desc1">
              <span>{{ messages.developer.banner.descText1 }}</span>
              <span>1,000,000</span>
              <span>{{ messages.developer.banner.descText2 }}</span>
            </div>
            <div
              class="text desc desc1-mobile"
              :class="{ chinese: language === 'zh' }"
            >
              <span>{{ messages.developer.banner.descText1 }}</span>
              <span>
                <span class="number">1,000,000</span>
                <span>{{ messages.developer.banner.descText2 }}</span>
              </span>
            </div>
            <div class="text desc desc2">
              {{ messages.developer.banner.descText3 }}
            </div>
            <div
              class="text desc desc-btn"
              :class="{ chinese: language === 'zh' }"
            >
              <span>
                {{ messages.developer.banner.descText4 }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="common-content protocol">
        <div class="text">{{ messages.developer.protocol.title }}</div>
        <div class="text">
          {{ messages.developer.protocol.descText1 }}
        </div>
        <div class="text">
          {{ messages.developer.protocol.descText2 }}
        </div>
      </div>
      <div class="common-content profile-social-network d-flex jc-between">
        <a
          class="profile"
          v-for="(pItem, index) in profileList"
          :key="index"
          :href="pItem.url"
        >
          <div class="header-wrap d-flex ai-center jc-between">
            <div class="text name">{{ pItem.name }}</div>
            <div class="enter-img"></div>
          </div>
          <img class="main-img" :src="pItem.img" />
          <div class="text desc">
            {{ pItem.desc }}
          </div>
        </a>
      </div>
      <div class="common-content products">
        <div class="text products-title">
          {{ messages.developer.products.title }}
        </div>
        <div class="text products-desc">
          {{ messages.developer.products.desc }}
        </div>
        <div class="products-item-wrap d-flex jc-between">
          <div
            class="p-item"
            v-for="(pItem, index) in productList"
            :key="index"
            :style="{ backgroundImage: `url(${pItem.img})` }"
            @click="index === 3 && openUrl(messages.developer.applyGrantUrl)"
          >
            <div class="text">{{ pItem.name }}</div>
          </div>
        </div>
        <div class="text check-project" @click="goCheckProject">
          {{ messages.developer.products.checkProject }}
        </div>
      </div>
      <div class="common-content banner2 d-flex ai-center jc-between">
        <div class="banner2-left" :class="{ chinese: language === 'zh' }">
          <div class="text">{{ messages.developer.banner2.title }}</div>
          <div class="text desc">{{ messages.developer.banner2.desc }}</div>
        </div>
        <div class="banner2-right">
          <div class="text" @click="openUrl(messages.developer.applyGrantUrl)">
            {{ messages.developer.applyGrant }}
          </div>
          <div
            class="text"
            @click="openUrl(messages.developer.viewDocumentsUrl)"
          >
            {{ messages.developer.viewDocuments }}
          </div>
        </div>
      </div>
    </div>
    <Sponsor :sponsroObj="messages.developer.sponsor" />
    <Footer :footer="messages.footer" />
    <Modal
      v-if="isModal"
      @close="onClose"
      :modalObj="messages.developer.modal"
      :url="messages.developer.applyGrantUrl"
      :language="language"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Sponsor from '../components/Sponsor.vue'
import Modal from '../components/Modal.vue'

import English from '../lang/en'
import Chinses from '../lang/cn'

export default {
  components: { Header, Footer, Sponsor, Modal },
  data() {
    return {
      english: English,
      chinses: Chinses,
      messages: {},
      isModal: false,
    }
  },
  computed: {
    ...mapGetters(['language']),
    profileList() {
      return [
        {
          name: this.messages.developer.profile.title,
          img: require('../assets/developer/profile.png'),
          desc: this.messages.developer.profile.desc,
          url:
            this.language === 'en'
              ? 'https://docs.5degrees.io/5degrees-protocol-of-web3/major-concepts/profile'
              : 'https://docs.5degrees.io/5degrees-protocol-of-web3/v/chinese/documentation/what-is-5degrees',
        },
        {
          name: this.messages.developer.socialNetwork.title,
          img: require('../assets/developer/social-network.png'),
          desc: this.messages.developer.socialNetwork.desc,
          url:
            this.language === 'en'
              ? 'https://docs.5degrees.io/5degrees-protocol-of-web3/documentation/what-is-5degrees'
              : 'https://docs.5degrees.io/5degrees-protocol-of-web3/v/chinese/documentation/what-is-5degrees',
        },
      ]
    },
    productList() {
      return [
        {
          name: 'GameFi + 5Degrees',
          img: require('../assets/developer/m-GameFi.png'),
        },
        {
          name: 'DeFi + 5Degrees',
          img: require('../assets/developer/m-DeFi.png'),
        },
        {
          name: 'Social + 5Degrees',
          img: require('../assets/developer/m-Social.png'),
        },
        {
          name: this.messages.developer.products.more,
          img: require('../assets/developer/m-more-pro.png'),
        },
      ]
    },
  },
  watch: {
    language: {
      immediate: true,
      handler() {
        if (this.language === 'en') {
          this.messages = this.english
        } else {
          this.messages = this.chinses
        }
      },
    },
  },
  methods: {
    onModal() {
      this.isModal = true
    },
    onClose() {
      this.isModal = false
    },
    openUrl(url) {
      window.open(url)
    },
    goCheckProject() {
      this.$router.push({
        name: 'Home',
        params: {
          goProject: '.projects',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.developer {
  .common-content {
    width: 12rem;
    margin: 0 auto;
  }
  .developer-content {
    position: relative;
    .developer-header {
      background: #f3feff;
      .developer-header-wrap {
        padding: 1rem 0.65rem;
        position: relative;
        .left-wrap {
          .title {
            font-size: 0.44rem;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 700;
          }
          .desc-mobile {
            margin: 0.19rem 0 0.74rem;
            div {
              font-size: 0.26rem;
              line-height: 0.37rem;
            }
          }
          .apply-wrap {
            width: 3.2rem;
            .button {
              cursor: pointer;
              width: 3.2rem;
              height: 0.58rem;
              line-height: 0.58rem;
              background: #2980fe;
              border-radius: 0.34rem;
              text-align: center;
              font-size: 0.18rem;
              color: #ffffff;
            }
            .button:hover {
              opacity: 0.9;
            }
            .view-documents {
              cursor: pointer;
              margin-top: 0.2rem;
              font-size: 0.16rem;
              text-align: center;
              color: #2980fe;
            }
            .view-documents:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .developer-img {
        position: absolute;
        top: 0.15rem;
        right: 0;
        width: 5.32rem;
        height: 5.83rem;
      }
    }
    .banner {
      padding-top: 0.54rem;
      padding: 0.54rem 0 0.36rem;
      background: #fff;
      .banner-wrap {
        cursor: pointer;
        position: relative;
        height: 3.38rem;
        background: url('../assets/developer/banner.png') no-repeat;
        background-size: contain;
        border-radius: 0.36rem;
        box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);

        .prize {
          width: 3.48rem;
          height: 3.2rem;
          position: absolute;
          left: 0.75rem;
          bottom: 0;
          img {
            position: absolute;
            width: 3.48rem;
            height: 3.2rem;
          }
          // :nth-child(2):hover {
          //   opacity: 0.8;
          // }
        }
        .prize-text {
          position: absolute;
          top: 2.2rem;
          left: 1.1rem;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          color: #ffffff;
        }
        .chain-wrap {
          position: absolute;
          top: 2.5rem;
          left: 1.1rem;
          .chain-icons {
            width: 1.41rem;
            height: 0.32rem;
            background: rgba(255, 255, 255, 0.24);
            border: 0.01rem solid rgba(255, 255, 255, 0.48);
            border-radius: 0.17rem;
            padding: 0 0.12rem;
            img {
              width: 0.24rem;
              height: 0.24rem;
            }
          }
          .enter-grey {
            margin-left: 1rem;
            width: 0.35rem;
            height: 0.16rem;
          }
        }
        .desc-wrap {
          position: absolute;
          top: 0.7rem;
          left: 5rem;
          .desc {
            font-size: 0.36rem;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            color: #ffffff;
          }
          .desc1 {
            span:nth-child(2) {
              margin: 0 0.1rem;
              font-size: 0.56rem;
              font-family: DINCond, DINCond-Black;
              font-weight: 900;
            }
          }
          .desc1-mobile {
            display: none;
          }
          .desc2 {
            margin: 0.12rem 0 0.26rem;
            font-size: 0.3rem;
          }
          .desc-btn {
            width: 5.14rem;
            height: 0.53rem;
            line-height: 0.53rem;
            background: rgba(255, 255, 255, 0.12);
            border: 0.01rem solid rgba(255, 255, 255, 0.8);
            border-radius: 0.49rem;
            font-size: 0.16rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            text-align: center;
          }
          .desc-btn:hover {
            opacity: 0.9;
          }
        }
      }
    }
    .protocol {
      background: #fff;
      border: 0.01rem solid #979797;
      border-radius: 0.36rem;
      padding: 0.52rem 0 0.66rem;
      :nth-child(1) {
        font-size: 0.3rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        line-height: 0.42rem;
      }
      :nth-child(2) {
        margin: 0.35rem 0 0.15rem;
      }
      :nth-child(n + 2) {
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        line-height: 0.22rem;
      }
    }
    .profile-social-network {
      margin-top: 0.36rem;
      .profile {
        width: 5.9rem;
        padding: 0.4rem 0.6rem 0.85rem;
        border: 0.01rem solid #979797;
        border-radius: 0.36rem;
        text-align: center;
        background: url('../assets/developer/profile-bg.png') no-repeat;
        background-size: contain;
        cursor: pointer;
        .header-wrap {
          .name {
            font-size: 0.3rem;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 700;
            line-height: 0.42rem;
          }
          .enter-img {
            width: 0.74rem;
            height: 0.34rem;
            background: url('../assets/developer/enter-grey.png') no-repeat;
            background-size: contain;
          }
        }
        .main-img {
          width: 3.66rem;
          height: 3.4rem;
        }
        .desc {
          color: #333333;
          line-height: 0.24rem;
        }
      }
      .profile:hover {
        padding: 0.38rem 0.58rem 0.83rem;
        border: 0.03rem solid #2980fe;
        .header-wrap {
          .enter-img {
            background: url('../assets/developer/enter-blue.png') no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .products {
      margin-top: 0.36rem;
      border: 0.01rem solid #979797;
      border-radius: 0.36rem;
      padding: 0.52rem 0.52rem 0.44rem;
      &-title {
        font-size: 0.3rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        line-height: 0.42rem;
      }
      &-desc {
        margin-top: 0.2rem;
        text-align: center;
        line-height: 0.22rem;
      }
      .products-item-wrap {
        margin: 0.52rem 0 0.42rem;
        .p-item {
          width: 2.59rem;
          height: 3.1rem;
          background: #ffffff;
          border-radius: 0.22rem;
          border: 0.02rem solid transparent;
          box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.06);
          background-repeat: no-repeat;
          background-size: contain;
          div {
            margin-top: 2.38rem;
            height: 0.36rem;
            font-size: 0.14rem;
            text-align: center;
            line-height: 0.36rem;
          }
        }
        :nth-child(4) {
          div {
            margin: 0 auto;
            margin-top: 2.38rem;
            width: 1.98rem;
            height: 0.36rem;
            background: #ffffff;
            border: 0.01rem solid #d7d7d7;
            border-radius: 0.22rem;
          }
        }
        :nth-child(4):hover {
          border: 0.02rem solid #2980fe;
          cursor: pointer;
          div {
            background: #2980fe;
            color: #fff;
          }
        }
      }
      .check-project {
        text-align: center;
        line-height: 0.22rem;
        cursor: pointer;
      }
      .check-project:hover {
        opacity: 0.7;
      }
    }
    .banner2 {
      margin-top: 0.36rem;
      margin-bottom: 0.36rem;
      padding: 0.35rem 0.61rem 0.35rem 2.08rem;
      width: 12rem;
      height: 1.68rem;
      background: url('../assets/developer/banner2.png') no-repeat;
      background-size: contain;
      &-left {
        :nth-child(1) {
          height: 0.3rem;
          font-size: 0.2rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.3rem;
        }
        :nth-child(2) {
          margin-top: 0.16rem;
          width: 3.45rem;
          height: 0.32rem;
          background: #1e75f2;
          border-radius: 0.16rem;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Regular;
          color: #ffffff;
          text-align: center;
          line-height: 0.32rem;
        }
      }
      &-right {
        :nth-child(1) {
          width: 3.2rem;
          height: 0.58rem;
          background: #ffffff;
          border-radius: 0.34rem;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: center;
          color: #2980fe;
          line-height: 0.58rem;
          cursor: pointer;
        }
        :nth-child(1):hover {
          opacity: 0.9;
        }
        :nth-child(2) {
          margin-top: 0.2rem;
          font-family: PingFangSC, PingFangSC-Regular;
          text-align: center;
          color: #ffffff;
          line-height: 0.2rem;
          cursor: pointer;
        }
        :nth-child(2):hover {
          opacity: 0.7;
        }
      }
    }
  }
  .sponsor {
    margin-bottom: 0.74rem;
  }
}
@media screen and(max-width:767px) {
  .developer {
    .common-content {
      width: 100%;
    }
    .developer-content {
      .developer-header {
        height: 10.25rem;
        .developer-header-wrap {
          padding: 0;
          justify-content: center;
          .left-wrap {
            .title {
              margin-top: 0.64rem;
              font-size: 0.54rem;
              text-align: center;
              line-height: 0.75rem;
            }
            .desc {
              display: none;
            }
            .desc-mobile {
              display: block;
              margin: 0.18rem 0 0.4rem;
              div {
                font-size: 0.32rem;
                text-align: center;
                line-height: 0.45rem;
              }
            }
            .apply-wrap {
              width: 5.74rem;
              margin: 0 auto;
              .button {
                width: 5.74rem;
                height: 0.88rem;
                line-height: 0.88rem;
                font-size: 0.26rem;
                border-radius: 0.44rem;
              }
              .button:hover {
                opacity: 1;
              }
              .view-documents {
                margin-top: 0.4rem;
                font-size: 0.24rem;
                line-height: 0.29rem;
              }
              .view-documents:hover {
                opacity: 1;
              }
            }
          }
          .developer-img {
            width: 5.32rem;
            height: 5.83rem;
            left: 50%;
            top: 4.8rem;
            right: auto;
            transform: translateX(-50%);
          }
        }
      }
      .banner {
        padding: 0.5rem 0.32rem 0.3rem;
        .banner-wrap {
          width: 6.86rem;
          height: 8rem;
          margin: 0 auto;
          background: url('../assets/developer/m-banner.png') no-repeat;
          background-size: contain;
          .prize {
            width: 4.12rem;
            height: 3.8rem;
            left: 50%;
            transform: translateX(-50%);
            img {
              width: 100%;
              height: 100%;
            }
          }
          .prize-text {
            font-size: 0.2rem;
            top: 6.6rem;
            left: 1.8rem;
          }
          .chain-wrap {
            top: 6.95rem;
            left: 1.8rem;
            .chain-icons {
              width: 1.7rem;
              height: 0.4rem;
              img {
                width: 0.3rem;
                height: 0.3rem;
              }
            }
            .enter-grey {
              width: 0.42rem;
              height: 0.2rem;
              margin-left: 1.14rem;
            }
          }
          .desc-wrap {
            top: 0.7rem;
            left: 50%;
            transform: translateX(-50%);
            .desc {
              font-size: 0.56rem;
            }
            .desc1 {
              display: none;
            }
            .desc1-mobile {
              width: 5rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              .number {
                margin-right: 0.1rem;
                font-size: 0.78rem;
                font-family: DINCond, DINCond-Black;
                font-weight: 900;
              }
              &.chinese {
                display: inline-block;
                width: 5.5rem;
                text-align: center;
                line-height: 0.8rem;
                .number {
                  margin-left: 0.1rem;
                }
              }
            }
            .desc2 {
              margin: 0.12rem 0 0.34rem;
              font-size: 0.26rem;
              text-align: center;
              line-height: 0.37rem;
            }
            .desc-btn {
              margin: 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 0.5rem;
              width: 4.82rem;
              height: 1.01rem;
              background: rgba(255, 255, 255, 0.12);
              border: 0.01rem solid rgba(255, 255, 255, 0.8);
              border-radius: 0.52rem;
              line-height: 0.33rem;
              font-size: 0.24rem;
              &.chinese {
                height: 0.69rem;
                line-height: 0.69rem;
              }
            }
            .desc-btn:hover {
              opacity: 1;
            }
          }
        }
      }
      .protocol {
        width: calc(100vw - 0.64rem);
        border-radius: 0.36rem;
        padding: 0.44rem 0.28rem;
        :nth-child(1) {
          font-size: 0.32rem;
          font-weight: 700;
          line-height: 0.45rem;
        }
        :nth-child(2) {
          margin: 0.3rem 0;
          font-size: 0.22rem;
        }
        :nth-child(n + 2) {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          line-height: 0.22rem;
        }
      }
      .profile-social-network {
        margin-top: 0.3rem;
        flex-direction: column;
        align-items: center;
        .profile {
          width: calc(100vw - 0.64rem);
          padding: 0.4rem 0.4rem 1rem 0.4rem;
          .header-wrap {
            .name {
              font-size: 0.32rem;
            }
            .enter-img {
              width: 0.74rem;
              height: 0.34rem;
            }
          }
          .main-img {
            width: 3.98rem;
            height: 3.7rem;
          }
          .desc {
            font-size: 0.24rem;
            line-height: 0.33rem;
          }
          &:nth-child(2) {
            margin-top: 0.3rem;
          }
        }
        .profile:hover {
          padding: 0.4rem 0.4rem 1rem 0.4rem;
          border: 0.01rem solid #979797;
          .header-wrap {
            .enter-img {
              background: url('../assets/developer/enter-grey.png') no-repeat;
              background-size: contain;
            }
          }
        }
      }
      .products {
        width: calc(100vw - 0.64rem);
        margin-top: 0.3rem;
        border-radius: 0.36rem;
        padding: 0.44rem 0.48rem 0.48rem;
        &-title {
          font-size: 0.32rem;
          font-weight: 700;
          line-height: 0.45rem;
        }
        &-desc {
          font-size: 0.22rem;
          line-height: 0.3rem;
        }
        .products-item-wrap {
          flex-wrap: wrap;
          margin: 0.4rem 0 0.38rem;
          .p-item {
            width: 2.62rem;
            height: 2.91rem;
            border-radius: 0.28rem;
            margin-bottom: 0.2rem;
            border: none;
            div {
              margin-top: 2.2rem;
              height: 0.36rem;
              font-size: 0.2rem;
              line-height: 0.36rem;
            }
          }
          :nth-child(4) {
            div {
              margin-top: 2.2rem;
              width: 2.2rem;
              height: 0.36rem;
              border: 0.01rem solid #d7d7d7;
              border-radius: 0.22rem;
            }
          }
          :nth-child(4):hover {
            cursor: pointer;
            border: none;
            div {
              background: #fff;
              color: #333;
            }
          }
        }
        .check-project {
          font-size: 0.24rem;
          line-height: 0.33rem;
        }
        .check-project:hover {
          opacity: 1;
        }
      }
      .banner2 {
        width: calc(100vw - 0.64rem);
        height: 3.9rem;
        margin-top: 0.3rem;
        margin-bottom: 0;
        padding: 0.43rem 0 0.14rem;
        background: url('../assets/developer/m-banner2.png') no-repeat;
        background-size: contain;
        flex-direction: column;
        justify-content: space-between;
        &-left {
          height: 1.6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &.chinese {
            justify-content: space-evenly;
          }
          :nth-child(1) {
            width: 5.5rem;
            font-size: 0.36rem;
            text-align: center;
            line-height: 0.5rem;
          }
          :nth-child(2) {
            margin-top: 0.12rem;
            width: 4.2rem;
            height: 0.4rem;
            line-height: 0.4rem;
            font-size: 0.36rem;
            font-size: 0.2rem;
          }
        }
        &-right {
          :nth-child(1) {
            width: 3.86rem;
            height: 0.78rem;
            line-height: 0.78rem;
            font-size: 0.26rem;
          }
          :nth-child(n + 1):hover {
            opacity: 1;
          }
          :nth-child(2) {
            margin-top: 0.25rem;
            font-size: 0.22rem;
            line-height: 0.29rem;
          }
        }
      }
    }
    .sponsor {
      margin-bottom: 0.3rem;
      margin-top: 0.3rem;
    }
  }
}
</style>
