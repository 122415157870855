<template>
  <div class="home">
    <Header :messages="messages" @goViewPosition="goViewPosition" />
    <MainContent :messages="messages" />
    <Footer :footer="messages.footer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import MainContent from '../components/MainContent.vue'
import English from '../lang/en'
import Chinses from '../lang/cn'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    MainContent,
  },
  data() {
    return {
      english: English,
      chinses: Chinses,
      messages: {},
    }
  },
  computed: { ...mapGetters(['language']) },
  watch: {
    language: {
      immediate: true,
      handler() {
        if (this.language === 'en') {
          this.messages = this.english
        } else {
          this.messages = this.chinses
        }
      },
    },
  },
  mounted() {
    if (this.$route.params?.goProject) {
      this.goViewPosition(this.$route.params.goProject)
    }
  },
  methods: {
    goViewPosition(selector) {
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 居中
      })
    },
  },
}
</script>
