<template>
  <div class="introduce">
    <div class="top">
      <div class="text title">{{ introduce.title }}</div>
      <div class="text desc">{{ introduce.desc }}</div>
      <div class="nft-mint" :class="{ active: account === '' }">
        <div class="header" v-show="account">
          <div class="wrap" ref="networkListBtn" @click="openNetworkList">
            <div class="text network-error" v-if="!wrongNetwork">
              {{ introduce.nftMint.networkError }}
            </div>
            <img :src="currentChainIcon" class="eth-icon" />
            <span class="text">{{
              account.slice(0, 6) + '...' + account.slice(-6)
            }}</span>
            <img src="../assets/common/down.png" class="down" />
          </div>
        </div>
        <div class="nft-content" :class="{ active: account === '' }">
          <div class="nft-instruction">
            <div class="nft">
              <img src="../assets/common/default-nft.png" class="default-nft" />
            </div>
            <div class="instruction-wrap">
              <div class="text instruction">
                {{ introduce.nftMint.desc }}
              </div>
              <div class="instruction-list-wrap">
                <a
                  class="instruction-list"
                  v-for="(ins, index) in instructionList"
                  :key="index"
                  :class="[ins.class]"
                  :href="ins.url && ins.url"
                  target="_blank"
                >
                  <div></div>
                  <span class="text">{{ ins.text }}{{ ins.count }}</span>
                </a>
              </div>
            </div>
          </div>
          <div
            class="text mint-btn"
            :class="{ collected: hasMint || endMint }"
            @click="onMint"
          >
            <span v-if="account && chainId === 1"> {{ connectText }}</span>
            <span v-else-if="account && chainId !== 1">{{
              introduce.switchChain
            }}</span>
            <span v-else>{{ introduce.connectWallet }}</span>
          </div>
          <div class="network-list" v-show="isNetworkList">
            <div class="text network-title">
              {{ introduce.nftMint.selectNetwork }}
            </div>
            <div
              class="network-wrap"
              v-for="(item, index) in networkList"
              :key="index"
              :class="{ active: item.selected }"
              @click="switchChain(item.chain)"
            >
              <img :src="item.icon" />
              <span class="text" :class="{ active: item.selected }">{{
                item.text
              }}</span>
              <div class="selected" :class="{ active: item.selected }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="doshed"></div>
        <div class="circle"></div>
        <div class="doshed doshed2"></div>
        <div class="circle circle2"></div>
        <div class="vertical-line"></div>
      </div>
      <div class="right">
        <div
          class="web-content"
          v-for="(item, index) in introduce.list"
          :key="index"
        >
          <div class="web" :class="{ web3: item.web3 }">
            <div class="text web-title" :class="{ 'web3-title': item.web3 }">
              {{ item.title }}
            </div>
            <div class="text web-desc">{{ item.desc }}</div>
          </div>
          <img
            class="web-img"
            :class="{ 'web3-img': item.web3 }"
            :src="item.image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Web3 from 'web3'
import { mapGetters } from 'vuex'
import {
  ContractMap,
  ZERO_ADDRESS,
  ChainIdMap,
  NodeMap,
  nativeCurrencyMap,
  ChainMap,
} from '../constants/index'
import ABI from '../constants/abi.json'

export default {
  props: {
    introduce: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      account: '',
      isNetworkList: false,
      wrongNetwork: false,
      chainId: '',
      currentChainId: '',
      currentChainIcon: require('../assets/common/chain-icon/eth.png'),
      chain: '',
      readonlyWeb3: {},
      networkList: [
        {
          icon: require('../assets/common/chain-icon/eth.png'),
          text: 'Ethereum',
          chain: 'eth',
          chainId: 1,
          selected: false,
        },
        // {
        //   icon: require('../assets/common/chain-icon/bsc.png'),
        //   text: 'BSC',
        //   chain: 'bsc',
        //   chainId: 56,
        //   selected: false,
        // },
        // {
        //   icon: require('../assets/common/chain-icon/polygon.png'),
        //   text: 'Heco',
        //   chain: 'heco',
        //   chainId: 128,
        //   selected: false,
        // },
      ],
      instructionList: [],
      connectText: 'Connect Wallet',
      hasMint: false,
      endMint: false,
      connected: false,
    }
  },
  computed: {
    ...mapGetters(['language']),
  },
  watch: {
    currentChainId(o, n) {
      // console.log(o, n)
      this.networkList.forEach((item) => {
        item.selected = false
        if (item.chainId === this.currentChainId) {
          item.selected = true
          this.currentChainIcon = item.icon
        }
      })
    },
    language: {
      immediate: true,
      handler() {
        this.instructionList = this.introduce.nftMint.list
        if (this.language === 'en') {
          this.connectText = 'Connect Wallet'
        } else {
          this.connectText = '连接钱包'
        }
      },
    },
  },
  created() {
    this.connectText = this.introduce.connectWallet
    this.initParams()
    this.initMint()
    // 拿到已连接网站的钱包地址，判断是否已连接钱包
    // if (window.ethereum) {
    //   this.account = window.ethereum.selectedAddress
    // }
  },
  mounted() {
    // 监听，除了点击自己，点击其他地方将自身隐藏
    document.addEventListener('click', this.onOutside)
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (account) => {
        this.connectWallect()
      })

      window.ethereum.on('chainChanged', (network) => {
        console.log('network', parseInt(network, 16))
        this.chainId = parseInt(network, 16)
        this.connectWallect()
      })
    }
  },
  methods: {
    initParams() {
      // console.log('window', window.ethereum)
      this.chain = ChainMap[window.ethereum.networkVersion]
      this.chainId = ChainIdMap[this.chain]
    },
    onMint() {
      // 判断当前链是否支持
      if (this.account && Number(this.chainId) === 1) {
        this.mint()
      } else if (this.account && Number(this.chainId) !== 1) {
        this.switchChain('eth')
      } else {
        this.connectWallect()
      }
    },
    openNetworkList() {
      this.isNetworkList = !this.isNetworkList
    },
    onOutside(e) {
      const networkListBtn = this.$refs.networkListBtn
      if (networkListBtn && !networkListBtn.contains(e.target)) {
        const contentWrap = document.querySelector('.network-list')
        if (contentWrap) {
          if (!contentWrap.contains(e.target)) {
            this.isNetworkList = false
          }
        }
      }
    },
    // 切换链
    async switchChain(chain) {
      console.log('switchChain', chain)
      this.chainId = ChainIdMap[chain]
      let chainId = '0x' + this.chainId.toString(16)

      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        })
        this.wrongNetwork = true
        this.currentChainId = this.chainId
        this.chain = chain
        this.isMint()
        // this.isNetworkList = false
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId,
                  chainName: chain.toUpperCase(),
                  rpcUrls: [NodeMap[chain]],
                  nativeCurrency: nativeCurrencyMap[chain],
                },
              ],
            })
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    async connectWallect() {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        this.account = accounts[0]
        this.connected = true
        this.currentChainId = Number(window.ethereum.chainId)
        if (!this.wrongNetwork) {
          this.connectText = this.introduce.collect
          this.isMint()
        }
        this.wrongNetwork = this.networkList.some((n) => {
          return n.chainId === this.currentChainId
        })
      }
    },
    // 白皮书拥有者默认显示eth上的数据
    initMint() {
      let web3 = new Web3(NodeMap['eth'])
      let instance = new web3.eth.Contract(ABI, ContractMap['eth'])
      instance.methods
        .metrics(ContractMap['eth'])
        .call({ from: ZERO_ADDRESS })
        .then((relation) => {
          // console.log('eht-tokenSupply', relation.tokenSupply)
          this.instructionList[0].count = relation.tokenSupply
        })
    },
    // 查询该账号是否mint
    async isMint() {
      // let web3 = this.readonlyWeb3[this.chain]
      let web3 = new Web3(NodeMap[this.chain])
      // console.log('web3', web3)
      let instance = new web3.eth.Contract(ABI, ContractMap[this.chain])
      await instance.methods
        .balanceOf(this.account, ContractMap[this.chain])
        .call({ from: ZERO_ADDRESS })
        .then((res) => {
          console.log('res', res)
          if (res * 1 === 1) {
            this.connectText = this.introduce.collected
            this.hasMint = true
          } else {
            this.connectText = this.introduce.collect
            this.hasMint = false
          }
        })
      // 多链的时候查询对应链的数据
      instance.methods
        .metrics(ContractMap[this.chain])
        .call({ from: ZERO_ADDRESS })
        .then((relation) => {
          console.log('relation', relation.tokenSupply)
          this.instructionList[0].count = relation.tokenSupply
          if (relation.tokenSupply * 1 === 2022) {
            this.endMint = true
            this.connectText = this.introduce.ended
          }
        })
    },
    mint() {
      let web3 = new Web3(window.ethereum)
      console.log('chain', ContractMap[this.chain])
      let instance = new web3.eth.Contract(ABI, ContractMap[this.chain])

      this.connectText = 'Collecting'
      instance.methods
        .mint(ContractMap[this.chain])
        .send({ from: this.account })
        .on('transactionHash', (hash) => {})
        .on('receipt', (receipt) => {
          console.log('receipt', receipt)
          this.connectText = this.introduce.collected
        })
        .on('error', (err) => {
          console.log('error')
          this.connectText = this.introduce.Collect
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.introduce {
  width: 12rem;
  margin: 0 auto;
  margin-top: 1.6rem;
  .text {
    font-size: 0.2rem;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: center;
    color: #9ea0a5;
  }
  .top {
    .title {
      font-size: 0.72rem;
      font-family: Inter, Inter-Bold;
      font-weight: 700;
      color: #000000;
      letter-spacing: 0.01rem;
    }
    .desc {
      width: 8.62rem;
      margin: 0 auto;
      margin-top: 0.32rem;
      font-size: 0.24rem;
    }
    .button {
      width: 1.88rem;
      margin: 0 auto;
      margin-top: 0.4rem;
      height: 0.56rem;
      line-height: 0.56rem;
      background: #2980fe;
      border-radius: 0.08rem;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .comming {
      margin-top: 0.16rem;
    }
    .nft-mint {
      position: relative;
      width: 10.8rem;
      margin: 0 auto;
      margin-top: 0.64rem;
      height: 2.52rem;
      background: #ffffff;
      border: 0.01rem solid #eaeff3;
      border-radius: 0.16rem;
      box-shadow: 0 0.32rem 0.8rem 0 rgba(184, 217, 255, 0.28);
      &.active {
        height: 1.92rem;
      }
      .header {
        position: absolute;
        top: 0;
        width: 10.8rem;
        height: 0.76rem;
        border-radius: 0.16rem;
        background: linear-gradient(89deg, #ffc6f6 0%, #96fff6 100%);
        box-shadow: 0 0.32rem 0.8rem 0 rgba(184, 217, 255, 0.28);
        display: flex;
        justify-content: flex-end;
        .wrap {
          height: 0.6rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          .network-error {
            width: 1.12rem;
            height: 0.32rem;
            line-height: 0.32rem;
            border: 0.01rem solid #f14a3d;
            border-radius: 0.04rem;
            font-size: 0.14rem;
            font-family: Inter, Inter-Medium;
            font-weight: 500;
            color: #f14a3d;
            margin-right: 0.16rem;
          }
          .eth-icon {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.16rem;
          }
          span {
            font-size: 0.16rem;
            font-family: Inter, Inter-Regular;
            font-weight: 400;
            text-align: left;
            color: #000000;
            text-shadow: 0 0.32rem 0.8rem 0 rgba(184, 217, 255, 0.28);
            margin-right: 0.08rem;
          }
          .down {
            width: 0.24rem;
            height: 0.26rem;
            margin-right: 0.24rem;
          }
        }
      }
      .nft-content {
        position: absolute;
        bottom: 0;
        border-radius: 0.16rem;
        z-index: 1000;
        background: #fff;
        width: 10.78rem;
        height: 1.92rem;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
        justify-content: space-between;
        &.active {
          height: 1.9rem;
        }
        .nft-instruction {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nft {
            position: relative;
            width: 1.28rem;
            height: 1.28rem;
            .default-nft {
              width: 1.28rem;
              height: 1.28rem;
            }
            .choose-btn {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0.56rem;
              height: 0.32rem;
              background: rgba(255, 255, 255, 0.64);
              border-radius: 0.16rem;
              backdrop-filter: blur(0.08rem);
              display: flex;
              justify-content: center;
              align-items: center;
              :nth-child(1) {
                margin-left: 0.03rem;
                width: 0.24rem;
                height: 0.24rem;
              }
              :nth-child(2) {
                width: 0.2rem;
                height: 0.2rem;
              }
            }
          }
          .instruction-wrap {
            .instruction {
              margin-left: 0.32rem;
              // width: 4.8rem;
              font-size: 0.24rem;
              font-family: Inter, Inter-Medium;
              font-weight: 500;
              text-align: left;
              color: #3a3b3d;
              text-shadow: 0 0.32rem 0.8rem 0 rgba(184, 217, 255, 0.28);
            }
            .instruction-list-wrap {
              margin-left: 0.32rem;
              margin-top: 0.32rem;
              display: flex;
              :nth-of-type(n + 2) {
                margin-left: 0.2rem;
              }
            }
          }
        }
        .mint-btn {
          display: none;
          width: 1.5rem;
          height: 0.51rem;
          background: #2980fe;
          border-radius: 0.08rem;
          box-shadow: 0 0.32rem 0.8rem 0 rgba(184, 217, 255, 0.28);
          font-size: 0.16rem;
          font-family: Inter, Inter-Semi Bold;
          font-weight: Semi Bold;
          text-align: center;
          color: #ffffff;
          line-height: 0.51rem;
          cursor: pointer;
          &.collected {
            background: #b8d9ff;
            color: #fff;
            pointer-events: none;
          }
        }
        .network-list {
          position: absolute;
          top: 0;
          right: 0;
          width: 2.28rem;
          background: #fff;
          border-radius: 0.08rem;
          border: 0.01rem solid #eaeff3;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0.24rem 0;
          box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(184, 217, 255, 0.4);
          .network-title {
            width: 1.8rem;
            font-size: 0.14rem;
            font-family: Inter, Inter-Medium;
            font-weight: 500;
            text-align: left;
          }
          .network-wrap {
            width: 1.8rem;
            height: 0.48rem;
            background: #fff;
            border-radius: 0.08rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.16rem;
            margin-top: 0.1rem;
            cursor: pointer;
            &.active {
              background: #2980fe;
            }
            img {
              width: 0.32rem;
              height: 0.32rem;
            }
            span {
              width: 0.8rem;
              font-size: 0.16rem;
              font-family: Inter, Inter-Medium;
              font-weight: 500;
              text-align: left;
              color: #000;
              &.active {
                color: #fff;
              }
            }
            .selected {
              width: 0.08rem;
              height: 0.08rem;
              background: #fff;
              border-radius: 50%;
              &.active {
                background: #3de94e;
              }
            }
          }
        }
      }
    }
  }
  .content {
    margin-top: 1rem;
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .doshed {
        height: 1.5rem;
        border: 0.01rem dashed #9ea0a5;
      }
      .doshed2 {
        height: 5.5rem;
      }
      .circle {
        margin: 0.05rem 0;
        width: 0.16rem;
        height: 0.16rem;
        border: 0.02rem solid #3a3b3d;
        border-radius: 0.23rem;
      }
      .circle2 {
        border: 0.02rem solid #2980fe;
      }
      .vertical-line {
        height: 1.5rem;
        border: 0.01rem solid #2980fe;
      }
    }
    .right {
      .web-content {
        display: flex;
        justify-content: space-between;
        &:nth-child(2) {
          margin-top: 1rem;
        }
        .web {
          width: 3.5rem;
          margin-left: 0.65rem;
          margin-top: 1rem;
          .web-title {
            text-align: left;
            font-size: 0.48rem;
            font-family: MiSans, MiSans-Semibold;
            font-weight: 600;
            color: #3a3b3d;
            letter-spacing: 0.01rem;
          }
          // .web3-title {
          //   color: #2980fe;
          // }
          .web-desc {
            margin-top: 0.05rem;
            text-align: left;
          }
        }
        .web3 {
          margin-top: 1rem;
        }
        .web-img {
          width: 6.96rem;
          height: 4.74rem;
        }
        .web3-img {
          width: 5.3rem;
          height: 5.13rem;
        }
      }
    }
  }
}
@media screen and (min-width: 767px) {
  @for $i from 1 through 3 {
    .instruction-list-#{$i} {
      display: flex;
      align-items: center;
      cursor: pointer;
      div {
        width: 0.2rem;
        height: 0.2rem;
        background: url(../assets/common/instruction/ins-off-#{$i}.png)
          no-repeat;
        background-size: contain;
      }
      span {
        margin-left: 0.08rem;
      }
    }
    .instruction-list-#{$i}:hover {
      div {
        background: url(../assets/common/instruction/ins-on-#{$i}.png)
          no-repeat;
        background-size: contain;
      }
      span {
        color: #2980fe;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  @for $i from 1 through 3 {
    .instruction-list-#{$i} {
      display: flex;
      align-items: center;
      cursor: pointer;
      div {
        width: 0.2rem;
        height: 0.2rem;
        background: url(../assets/common/instruction/ins-off-#{$i}.png)
          no-repeat;
        background-size: contain;
      }
      span {
        margin-left: 0.08rem;
      }
    }
  }
  .introduce {
    width: 100vw;
    padding: 0 0.56rem;
    .text {
      font-size: 0.24rem;
    }
    .top {
      .title {
        font-size: 0.56rem;
      }
      .desc {
        width: auto;
        margin-top: 0.2rem;
        font-size: 0.26rem;
      }
      .button {
        width: 2.5rem;
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.24rem;
      }
      .nft-mint {
        width: 6rem;
        height: 5.6rem;
        &.active {
          height: 4.15rem;
        }
        .header {
          width: 6rem;
          height: 1.2rem;
          .wrap {
            height: 1rem;
            .network-error {
              width: 2rem;
              height: 0.5rem;
              line-height: 0.5rem;
            }
            .eth-icon {
              width: 0.4rem;
              height: 0.4rem;
            }
            span {
              font-size: 0.24rem;
            }
          }
        }
        .nft-content {
          width: 5.98rem;
          flex-direction: column;
          justify-content: space-between;
          height: 4.1rem;
          padding: 0.5rem 0;
          &.active {
            height: 4.12rem;
          }
          .nft-instruction {
            height: 100%;
            flex-direction: column;
            .instruction-wrap {
              .instruction {
                width: 5rem;
                margin: 0 auto;
                margin-top: 0.32rem;
                font-size: 0.28rem;
                text-align: center;
              }
              .instruction-list-wrap {
                margin: 0;
                margin-top: 0.1rem;
                justify-content: center;
              }
            }
          }
          .mint-btn {
            width: 2.5rem;
            height: 0.6rem;
            line-height: 0.6rem;
            font-size: 0.24rem;
          }
          .network-list {
            width: 5rem;
            left: 50%;
            transform: translateX(-50%);
            .network-title {
              width: 4rem;
              font-size: 0.3rem;
            }
            .network-wrap {
              margin-top: 0.3rem;
              width: 4rem;
              height: 0.8rem;
              img {
                width: 0.5rem;
                height: 0.5rem;
              }
              span {
                width: 1.5rem;
                font-size: 0.3rem;
              }
              .selected {
                width: 0.2rem;
                height: 0.2rem;
              }
            }
          }
        }
      }
    }
    .content {
      // .left {
      // }
      .right {
        .web-content {
          flex-direction: column;
          margin-left: 0.65rem;
          &:nth-child(2) {
            margin-top: 0;
          }
          .web {
            margin-left: 0;
            margin-top: 0.8rem;
            width: 100%;
            .web-title {
              font-weight: 700;
            }
            .web-desc {
              font-size: 0.26rem;
            }
          }
          .web-img {
            margin-top: 0.5rem;
            width: 5.568rem;
            height: auto;
          }
          .web3-img {
            width: 4.24rem;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
