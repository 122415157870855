<template>
  <div class="footer">
    <div class="pc">
      <div class="content">
        <div class="left">
          <span class="text title">{{ footer.title }}</span>
          <span class="text desc">{{ footer.desc }}</span>
          <div class="links">
            <a
              :href="item.url"
              v-for="item in linkList"
              :key="item.class"
              :class="[item.class]"
            >
            </a>
          </div>
        </div>
        <div class="right">
          <div class="resources">
            <div class="text title">{{ footer.resources.title }}</div>
            <div class="list">
              <a
                :href="item.url && item.url"
                v-for="item in footer.resources.list"
                :key="item.text"
                class="text"
              >
                {{ item.text }}
              </a>
            </div>
          </div>
          <div class="resources projects">
            <div class="text title">{{ footer.projects.title }}</div>
            <div class="list">
              <a
                :href="item.url"
                v-for="item in footer.projects.list"
                :key="item.text"
                class="text"
              >
                {{ item.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="horizontal-line"></div>
        <div class="text copyright">{{ copyright }}</div>
      </div>
    </div>
    <div class="mobile">
      <div class="left">
        <span class="text title">{{ footer.title }}</span>
        <span class="text desc">{{ copyright }}</span>
      </div>
      <div class="right">
        <a
          :href="item.url"
          v-for="item in linkList"
          :key="item.class"
          :class="[item.class]"
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    footer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      linkList: [
        {
          class: 'link-1',
          url: 'https://twitter.com/5Degrees_',
        },
        {
          class: 'link-2',
          url: 'https://github.com/5DegreesProtocol',
        },
        {
          class: 'link-3',
          url: 'https://medium.com/@5Degrees',
        },
        {
          class: 'link-4',
          url: 'https://discord.com/invite/mumhnFtgvM',
        },
      ],
      copyright: 'Copyright © 2022 5Degrees',
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 12rem;
  margin: 0 auto;
  .text {
    font-size: 0.14rem;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: left;
    color: #9ea0a5;
  }
  .pc {
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 0.24rem;
          font-family: MiSans, MiSans-Semibold;
          font-weight: 600;
          color: #3a3b3d;
        }
        .desc {
          width: 3.95rem;
          margin-top: 0.1rem;
        }
        .links {
          display: flex;
          margin-top: 0.32rem;
          :nth-of-type(n + 2) {
            margin-left: 0.2rem;
          }
        }
      }
      .right {
        margin-right: 0.87rem;
        display: flex;
        .resources {
          .title {
            font-size: 0.2rem;
            font-weight: 600;
            color: #000000;
          }
          .list {
            margin-top: 0.2rem;
            display: flex;
            flex-direction: column;
            // a {
            //   font-family: MiSans, MiSans-Medium;
            //   font-weight: 500;
            // }
            a:hover {
              color: #2980fe;
            }
            :nth-of-type(n + 2) {
              margin-top: 0.1rem;
            }
          }
        }
        .projects {
          margin-left: 1rem;
        }
      }
    }
    .bottom {
      margin-top: 1rem;
      .horizontal-line {
        height: 0.01rem;
        background: #f1f1f1;
      }
      .copyright {
        margin-top: 0.2rem;
        margin-bottom: 0.48rem;
        text-align: center;
      }
    }
  }
  .mobile {
    display: none;
  }
}
@media screen and (min-width: 767px) {
  @for $i from 1 through 4 {
    .link-#{$i} {
      width: 0.32rem;
      height: 0.32rem;
      background: url(../assets/common/link-off-#{$i}.png) no-repeat;
      background-size: contain;
    }
    .link-#{$i}:hover {
      background: url(../assets/common/link-on-#{$i}.png) no-repeat;
      background-size: contain;
    }
  }
}
@media screen and (max-width: 767px) {
  @for $i from 1 through 4 {
    .link-#{$i} {
      width: 0.4rem;
      height: 0.4rem;
      background: url(../assets/common/link-off-#{$i}.png) no-repeat;
      background-size: contain;
    }
  }
  .footer {
    width: 100vw;
    padding: 0.48rem 0.32rem 0.76rem;
    .pc {
      display: none;
    }
    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 0.3rem;
          font-weight: 700;
          font-family: MiSans, MiSans-Semibold;
          color: #3a3b3d;
        }
        .desc {
          width: 3.95rem;
          margin-top: 0.1rem;
        }
      }
      .right {
        display: flex;
        :nth-of-type(n + 2) {
          margin-left: 0.2rem;
        }
      }
    }
  }
}
</style>
