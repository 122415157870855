<template>
  <div class="modal">
    <div class="modal-content d-flex flex-column ai-center">
      <img class="modal-img" src="../assets/developer/modal-img.png" />
      <div class="text title">{{ modalObj.title }}</div>
      <div class="desc-wrap" :class="{ chinese: language === 'zh' }">
        <div
          class="desc-item d-flex"
          v-for="(item, index) in descList"
          :key="index"
        >
          <div class="circle"></div>
          <span class="text">
            {{ item.text }}
          </span>
        </div>
      </div>
      <div
        class="text start-btn"
        :class="{ chinese: language === 'zh' }"
        @click="goForm"
      >
        {{ modalObj.startBuilding }}
      </div>
      <img
        class="delete"
        src="../assets/developer/close.png"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalObj: {
      type: Object,
    },
    language: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  computed: {
    descList() {
      return [
        { text: this.modalObj.descText1 },
        { text: this.modalObj.descText2 },
        { text: this.modalObj.descText3 },
      ]
    },
  },
  methods: {
    goForm() {
      window.open(this.url)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 0.36rem;
    padding: 0.36rem 0.5rem 0.42rem;
    .modal-img {
      width: 1.3rem;
      height: 1.3rem;
    }
    .title {
      margin: 0.04rem 0 0.2rem;
      height: 0.42rem;
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #333333;
      line-height: 0.42rem;
    }
    .desc-wrap {
      width: 6.1rem;
      background: #f5f9ff;
      border-radius: 0.16rem;
      padding: 0.3rem 0.42rem;
      &.chinese {
        width: 4.36rem;
        padding: 0.3rem;
      }
      .desc-item {
        .circle {
          margin: 0.09rem 0.1rem 0 0;
          width: 0.06rem;
          height: 0.06rem;
          background: #333333;
          border-radius: 50%;
        }
        :nth-child(2) {
          flex: 1;
          color: #333333;
          line-height: 0.22rem;
        }
        &:nth-child(2) {
          margin: 0.2rem 0;
        }
      }
    }
    .start-btn {
      margin-top: 0.3rem;
      width: 6.1rem;
      height: 0.68rem;
      line-height: 0.68rem;
      background: #2980fe;
      border-radius: 0.32rem;
      text-align: center;
      color: #ffffff;
      &.chinese {
        width: 4.36rem;
      }
    }
    .delete {
      position: absolute;
      top: 0.16rem;
      right: 0.16rem;
      width: 0.35rem;
      height: 0.35rem;
      cursor: pointer;
    }
  }
}

@media screen and(max-width:767px) {
  .modal {
    &-content {
      width: 94vw;
      padding: 0.3rem 0.3rem 0.4rem;
      border-radius: 0.36rem;
      .modal-img {
        width: 1.6rem;
        height: 1.6rem;
      }
      .title {
        font-size: 0.32rem;
      }
      .desc-wrap {
        width: 6.1rem;
        &.chinese {
          width: 6.1rem;
          padding: 0.3rem;
        }
        .desc-item {
          .circle {
            margin: 0.12rem 0.12rem 0 0;
            width: 0.06rem;
            height: 0.06rem;
          }
          :nth-child(2) {
            font-size: 0.24rem;
            line-height: 0.33rem;
          }
          &:nth-child(2) {
            margin: 0.3rem 0;
          }
        }
      }
      .start-btn {
        width: 6.1rem;
        height: 0.88rem;
        line-height: 0.88rem;
        font-size: 0.24rem;
        border-radius: 0.44rem;
        &.chinese {
          width: 6.1rem;
        }
      }
      .delete {
        width: 0.49rem;
        height: 0.49rem;
      }
    }
  }
}
</style>
