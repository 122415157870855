<template>
  <div class="main-content">
    <div class="top-content">
      <div class="top">
        <div class="left">
          <div class="title">{{ messages.title1 }}</div>
          <div class="title">{{ messages.title2 }}</div>
          <div class="title">{{ messages.title3 }}</div>
          <div class="desc">{{ messages.desc }}</div>
        </div>
        <img class="right" :src="messages.mainImg" />
      </div>
    </div>
    <Introduce :introduce="messages.introduce" />
    <Projects :projects="messages.projects" />
  </div>
</template>

<script>
import Introduce from './Introduce.vue'
import Projects from './Projects.vue'
export default {
  components: { Introduce, Projects },
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  .top-content {
    background: url('../assets/common/main-bg.png') no-repeat;
    background-size: cover;
    .top {
      width: 12rem;
      margin: 0 auto;
      position: relative;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        .circle {
          position: absolute;
          left: -0.35rem;
          top: 1.36rem;
          width: 0.46rem;
          height: 0.46rem;
          background: linear-gradient(139deg, #47ff5a, #267dff 100%);
          border-radius: 1.08rem;
        }
        .title {
          font-size: 0.8rem;
          font-family: Inter, Inter-Bold;
          font-weight: 700;
          text-align: left;
          color: #000000;
          letter-spacing: 0.02rem;
        }
        // 字体渐变效果
        .title2 {
          margin-left: 0.3rem;
          background: linear-gradient(to right, #ff4105, #31f9b1, #2596ff);
          background-clip: text;
          -o-background-clip: text;
          -ms-background-clip: text;
          -moz-background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
        .desc {
          margin-top: 0.64rem;
          font-size: 0.24rem;
          font-family: MiSans, MiSans-Regular;
          font-weight: 400;
          text-align: left;
          color: #9ea0a5;
        }
        .circle2 {
          position: absolute;
          top: 6.03rem;
          left: 50%;
          width: 0.22rem;
          height: 0.22rem;
          background: linear-gradient(139deg, #ff7347, #e126ff 100%);
          border-radius: 1.08rem;
        }
      }
      .right {
        width: 5.77rem;
        // height: 6.12rem;
      }
    }
  }
}
@media screen and (min-width: 767px) {
}
@media screen and (max-width: 767px) {
  .main-content {
    .top-content {
      background: url('../assets/common/main-en-bg.png') no-repeat;
      background-size: contain;
      .top {
        width: 100vw;
        flex-direction: column;
        .left {
          .title {
            text-align: center;
          }
          .desc {
            margin-top: 0.3rem;
            font-size: 0.26rem;
          }
        }
        .right {
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
