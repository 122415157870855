//
//
const projectList = [
  {
    title: 'Fans3.0',
    desc: 'web3.0中的社交网络。你可以通过钱包地址对应到某账户个人信息、甚至成为KOL。一旦您通过 5Degrees协议建立社交关系，就可以在任何基于该协议构建的web3.0 应用中使用。',
    buttonText: '打开',
    image: require('../assets/common/projects/fans3.png'),
    url: 'https://fans3.5degrees.io/',
  },
  {
    title: 'Web3 社交桥梁',
    desc: '5Degrees 是一个去中心化的社交协议，它可以帮助人们轻松探索Web3.0，旨在将Web2.0用户与Web3.0联系起来。',
    buttonText: '打开',
    image: require('../assets/common/projects/web3-social-bridge.png'),
    url: 'https://bridge.5degrees.io/',
  },
  {
    title: 'UniSwap+社交',
    desc: '为 Etheruem 上的 Uniswap 提供社交功能，以帮助您跟踪某账户中的交易动态，购买或出售等。',
    buttonText: '打开',
    image: require('../assets/common/projects/uni-swap.png'),
    url: 'https://uni.5degrees.io/#/follow',
  },
  {
    title: 'PancakeSwap+社交',
    desc: '为BSC上的PancakeSwap提供社交功能，您可以跟踪某账户中的交易动态，特别是购买或出售的动态。',
    buttonText: '打开',
    image: require('../assets/common/projects/pancake-swap.png'),
    url: 'https://cake.5degrees.io/#/follow',
  },
  {
    title: '即时提醒',
    desc: '关注您喜欢的帐户，每当他们有交易在区块链上发生时，您都会通过电子邮件收到通知。',
    buttonText: '即将上线',
    // image: require('../assets/common/projects/following-alert.png'),
    image: require('../assets/common/projects-grey/following-alert.png'),
  },
  {
    title: 'Transit NFT+社交',
    desc: '通过关注某些用户，了解他们正在购买、出售和持有哪些NFT。成为NFT经济的新潮儿。',
    buttonText: '即将上线',
    // image: require('../assets/common/projects/transit-nft.png'),
    image: require('../assets/common/projects-grey/transit-nft.png'),
  },
  {
    title: '热门话题',
    desc: '及时发现最热话题，帮助您了解区块链中正在发生的事情。分析或关注它，帮助您做更深入的了解！',
    buttonText: '即将上线',
    // image: require('../assets/common/projects/trending-topic.png'),
    image: require('../assets/common/projects-grey/trending-topic.png'),
  },
  {
    title: '地址簿+社交',
    desc: '一旦您的社交关系被记录在链上，您就可以在钱包上直接使用。例如，您可以访问个人资料或发送交易。',
    buttonText: '即将上线',
    // image: require('../assets/common/projects/address-book.png'),
    image: require('../assets/common/projects-grey/address-book.png'),
  },
  {
    title: '加入我们',
    desc: '提交您基于5Degrees进行构建的项目，让我们一起展示给社区。',
    buttonText: '立即提交',
    image: require('../assets/common/projects/juin-us.png'),
    url: 'https://www.wenjuan.com/s/UZBZJvz3Kk2/#',
  },
]
export default {
  fiveDegrees: '5Degrees',
  whiteList: '白名单',
  whiteListUrl: 'https://www.wenjuan.com/s/UZBZJvKuyP/',
  document: '文档',
  documentUrl:
    'https://docs.5degrees.io/5degrees-protocol-of-web3/v/chinese/documentation/what-is-5degrees',
  projectsText: '项目',
  whitePaper: '白皮书',
  developerText: 'Grant',
  title1: 'Web3.0',
  title2: '社交网络',
  title3: '全新协议',
  desc: '掌控自有数据，在Web3.0中遇见你的朋友们',
  mainImg: require('../assets/common/main.png'),
  introduce: {
    title: '什么是 5Degrees',
    desc: '5Degrees 是一个去中心化的社交协议，它可以帮助人们轻松探索Web3.0',
    buttonText: '白皮书',
    coming: '(即将上线)',
    connectWallet: '连接钱包',
    collect: '收集',
    collecting: '收集中',
    collected: '已收集',
    switchChain: '切换链',
    ended: '活动结束',
    nftMint: {
      desc: '仅有2022份白皮书NFT提供给ETH上的用户mint。',
      networkError: '网络错误',
      selectNetwork: '选择钱包网络',
      list: [
        {
          class: 'instruction-list-1',
          text: '拥有者:',
          count: '2022',
        },
        {
          class: 'instruction-list-2',
          text: '在OpenSea中查看',
          url: 'https://opensea.io/collection/5degrees',
        },
        {
          class: 'instruction-list-3',
          text: '详情',
          url: 'https://mirror.xyz/0x468f4377E50D669fB4A096022ae7b27CFd14326d/Nv_VqWB_aTQHMIT0_pe-R2ReZW0vQCmUTXy5jLot_to',
        },
      ],
    },
    list: [
      {
        title: 'Web2.0',
        desc: '各应用中的社交关系都需重新建立',
        image: require('../assets/common/web2.png'),
      },
      {
        web3: true,
        title: 'Web3.0',
        desc: '用户关系建立后，后续所有DApp都可以根据链上数据自动关联',
        image: require('../assets/common/web3.png'),
      },
    ],
  },
  projects: {
    title: '基于 5Degrees 协议构建的项目',
    desc: '社区中通过 5Degrees 协议启用社交功能的项目.',
    list: projectList,
  },
  footer: {
    title: '5Degrees',
    desc: '掌控自有数据，在Web3.0中遇见你的朋友们',
    resources: {
      title: '联系我们',
      list: [
        {
          text: 'Twitter',
          url: 'https://twitter.com/5Degrees_',
        },
        {
          text: 'GitHub',
          url: 'https://github.com/5DegreesProtocol',
        },
        {
          text: '品牌资源',
          url: 'https://www.5degrees.io/5degrees.zip',
        },
      ],
    },
    projects: {
      title: '项目',
      list: projectList,
    },
  },
  developer: {
    applyGrant: '申请 Grant',
    applyGrantUrl:
      'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__kp0061UQkFWSVVEQ0tPU09CNDdDN1JVTEhVVTgxUy4u',
    viewDocuments: '查看文档 >>',
    viewDocumentsUrl:
      'https://docs.5degrees.io/5degrees-protocol-of-web3/v/chinese/documentation/what-is-5degrees',
    header: {
      title: '让我们开始建设 Web3.0！',
      desc: '灵感，创造力 赋予给所有的Web3.0项目！',
      mobileDesc1: '灵感，创造力',
      mobileDesc2: '赋予给所有的Web3.0项目！',
    },
    banner: {
      submit: '您需要提交什么？',
      descText1: '赢取超过',
      descText2: '美元的总奖金',
      // descText2: '美金',
      descText3: '最高个人奖金300,000美元',
      descText4: '获得影响Web3.0 历史进程的机会!',
    },
    protocol: {
      title: '关于5Degrees协议',
      descText1:
        '5Degrees 是一个去中心化的社交协议，它可以帮助人们轻松探索Web3.0',
      descText2: '用户关系建立后，后续所有DApp都可以根据链上数据自动关联',
    },
    profile: {
      title: '个人信息',
      desc: 'ProfileNFT遵循了EIP1155的metadata数据格式，您可以轻松设置头像、昵称、Twitter、E-mail、个人简介等等相关信息，头像可以支持任意格式，它可以是简单的图片链接，也可以是一个NFT。',
    },
    socialNetwork: {
      title: '社交网络',
      desc: '你可以通过5度协议，关注/批量关注一些用户。在5Degrees协议中，当用户关注一个创作者时，他可以同时获得该创作者的个人NFT。不同的创作者或者社区可对其进行编码，甚至赋予额外的价值。同时，你也可以通过地址快速地查询到它的关系网络。',
    },
    products: {
      title: '你可以在这些产品中使用5Degrees协议',
      desc: '现已支持ETH、BNB、POLYGON、OKX 链等',
      more: '更多领域等你挖掘',
      checkProject: '查看项目demo >>',
    },
    banner2: {
      title: '马上使用5度协议搭建项目！',
      desc: '让我们一起做一件有趣且有意义的事情！',
    },
    sponsor: {
      title: '赞助商及合作方',
      desc: '成为赞助商 >>',
      descUrl:
        'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__kp0061URUY3VFI5MEVGTklKU0oyVDVQVFBOVVBDMC4u',
    },
    modal: {
      title: '您需要提交什么',
      descText1: 'GitHub地址/项目链接/说明文档以供评选和测试',
      descText2: '一段3min内的产品演示视频（YouTube渠道）',
      descText3: '您的联系方式',
      startBuilding: '开始建设',
    },
  },
}
