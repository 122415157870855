//
//
const projectList = [
  {
    title: 'Fans3.0',
    desc: 'Your social network on Web3.0. You can get access to the profile of address, and even become an KOL. Once relations are connected, you can use it on any other Web3.0 DApps.',
    buttonText: 'Open',
    image: require('../assets/common/projects/fans3.png'),
    url: 'https://fans3.5degrees.io/',
  },
  {
    title: 'Web3 Social Bridge',
    desc: '5Degrees, the Portal to the New, Open lnternet, aims to link the Web2.0 users to Web3.0',
    buttonText: 'Open',
    image: require('../assets/common/projects/web3-social-bridge.png'),
    url: 'https://bridge.5degrees.io/',
  },
  {
    title: 'UniSwap+Social',
    desc: 'Empower Uniswap on Ethereum with social features, to help you follow up the accounts you are concerned about and know what they are buying or selling.',
    buttonText: 'Open',
    image: require('../assets/common/projects/uni-swap.png'),
    url: 'https://uni.5degrees.io/#/follow',
  },
  {
    title: 'PancakeSwap+Social',
    desc: 'Empower PancakeSwap on BSC with social features, to help you follow up the accounts that you are concerned about and know what they are buying or selling.',
    buttonText: 'Open',
    image: require('../assets/common/projects/pancake-swap.png'),
    url: 'https://cake.5degrees.io/#/follow',
  },
  {
    title: 'Following Alert',
    desc: 'Follow the accounts you like. Whenever they have a transaction on chain, you will get notified through email.',
    buttonText: 'Coming soon',
    // image: require('../assets/common/projects/following-alert.png'),
    image: require('../assets/common/projects-grey/following-alert.png'),
  },
  {
    title: 'Transit NFT+Social',
    desc: 'By following certain accounts, you can find out what kinds of NFT they are buying, selling and holding and become a trendy player of NFT.',
    buttonText: 'Coming soon',
    // image: require('../assets/common/projects/transit-nft.png'),
    image: require('../assets/common/projects-grey/transit-nft.png'),
  },
  {
    title: 'Trending Topic',
    desc: "Find out about the hottest topic in time, to help you know what's happening. Analyze or follow it to know more about the topic.",
    buttonText: 'Coming soon',
    // image: require('../assets/common/projects/trending-topic.png'),
    image: require('../assets/common/projects-grey/trending-topic.png'),
  },
  {
    title: 'Address Book+Social',
    desc: 'Once your relations are recorded on chain, you can use it on your wallet directly. For example, you can access a profile and initiate a transaction.',
    buttonText: 'Coming soon',
    // image: require('../assets/common/projects/address-book.png'),
    image: require('../assets/common/projects-grey/address-book.png'),
  },
  {
    title: 'Join Us',
    desc: 'Submit your projects that built on 5Degrees, and let’s show them here to the community.',
    buttonText: 'Submit',
    image: require('../assets/common/projects/juin-us.png'),
    url: 'https://docs.google.com/forms/d/e/1FAIpQLSc0Qz1cRJm2CCqnlGe8t9ILSLfDcExuDhXkfu4YhH_cASAOfg/viewform',
  },
]
export default {
  fiveDegrees: '5Degrees',
  whiteList: 'Whitelist',
  whiteListUrl:
    'https://docs.google.com/forms/d/e/1FAIpQLSe9ZlN454NHvIxJ3mjHwXmS_ln6gIpopIgaYAE7qQyKCpPTHw/viewform',
  document: 'Document',
  documentUrl:
    'https://docs.5degrees.io/5degrees-protocol-of-web3/documentation/what-is-5degrees',
  projectsText: 'Projects',
  whitePaper: 'Whitepaper',
  developerText: 'Grant',
  title1: 'Social Network',
  title2: 'Protocol in',
  title3: 'Web3.0',
  desc: 'Own your data, and meet your friends in Web3.0',
  mainImg: require('../assets/common/main-en.png'),
  introduce: {
    title: 'What is 5Degrees',
    desc: '5Degrees is a protocol for decentralized social network, which helps people to explore Web3.0 easily',
    buttonText: 'Whitepaper',
    coming: '(Coming soon)',
    connectWallet: 'Connect Wallet',
    collect: 'Collect',
    collecting: 'Collecting',
    collected: 'Collected',
    switchChain: 'swtich chain',
    ended: 'Ended',
    nftMint: {
      desc: 'Only 2022 pieces of Whitepaper NFT are available for all ETH users.',
      networkError: 'Network Error',
      selectNetwork: "Select wallet's network",
      list: [
        {
          class: 'instruction-list-1',
          text: 'Owners:',
          count: '2022',
        },
        {
          class: 'instruction-list-2',
          text: 'View in OpenSea',
          // url: 'https://opensea.io/collection/5degrees',
          url: 'https://opensea.io/collection/5degrees-whitepaper',
        },
        {
          class: 'instruction-list-3',
          text: 'Details',
          url: 'https://mirror.xyz/0x468f4377E50D669fB4A096022ae7b27CFd14326d/WGYSpnSgePjSc5T5G7lIZoPB6LHP7pE_6wxugg9lMSk',
        },
      ],
    },
    list: [
      {
        title: 'Web2.0',
        desc: 'The social networks in each app need to be re-established',
        image: require('../assets/common/web2.png'),
      },
      {
        web3: true,
        title: 'Web3.0',
        desc: "After a user's social network is established, all subsequent DApps can be automatically associated according to the data on chain",
        image: require('../assets/common/web3.png'),
      },
    ],
  },
  projects: {
    title: 'Projects built on 5Degrees',
    desc: 'Projects that enable their social features through 5Degrees in the community.',
    list: projectList,
  },
  footer: {
    title: '5Degrees',
    desc: 'Own your data, and meet your friends in Web3.0',
    resources: {
      title: 'Resources',
      list: [
        {
          text: 'Twitter',
          url: 'https://twitter.com/5Degrees_',
        },
        {
          text: 'GitHub',
          url: 'https://github.com/5DegreesProtocol',
        },
        {
          text: 'Brand Kit',
          url: 'https://www.5degrees.io/5degrees.zip',
        },
      ],
    },
    projects: {
      title: 'Projects',
      list: projectList,
    },
  },
  developer: {
    applyGrant: 'Apply a Grant',
    applyGrantUrl:
      'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__kp0061UNlIzUEhNVEtGOEpRQlFMVDRPNk8xOFlGMC4u',
    viewDocuments: 'View Documents >>',
    viewDocumentsUrl:
      'https://docs.5degrees.io/5degrees-protocol-of-web3/documentation/what-is-5degrees',
    header: {
      title: "LET'S BUILD WEB3.0",
      desc: 'Creativity and Inspiration For all the amazing web3.0 projects!',
      mobileDesc1: 'Creativity and Inspiration',
      mobileDesc2: 'For all the amazing web3.0 projects!',
    },
    banner: {
      submit: 'What do you need to submit?',
      descText1: 'The total prize is',
      descText2: 'USDT',
      descText3: 'The max single prize is 300,000 USDT',
      descText4: 'Opportunities to influence the historical course of Web3.0',
    },
    protocol: {
      title: 'About 5Degrees Social Protocol',
      descText1:
        '5Degrees is a protocol for decentralized social network, which helps people to explore Web3.0 easily.',
      descText2:
        'After a user’s social network is established, all subsequent DApps can be automatically associated according to the data on chain.',
    },
    profile: {
      title: 'Profile',
      desc: 'Profile NFT follows the metadata format of EIP-1155. You can easily set your avatar, nickname, Twitter account, Email address, profiles, and so on. Any format is supported for your avatar. It can be a simple link of the picture or an NFT.',
    },
    socialNetwork: {
      title: 'Social network',
      desc: 'You can follow some users through the 5Degrees Social Protocol. When you follow a creator, you can also obtain the creator’s personal NFT at the same time. Different creators and communities can encode it and even give it additional value. At the same time, you can quickly query the users’ relationship network by their addresses.',
    },
    products: {
      title: 'You can use the 5Degrees protocol in these products',
      desc: 'Now supports Ethereum, BNB Chain, Polygon, and OKXChain, etc.',
      more: 'More fields',
      checkProject: 'Check demo >>',
    },
    banner2: {
      title: 'START BUILDING WITH 5DEGREES SOCIAL PROTOCOL',
      desc: "Let's build something really cool!",
    },
    sponsor: {
      title: 'SPONSOR & PARTNER',
      desc: 'Sponsor >>',
      descUrl:
        'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__kp0061UNkUwWEY4Mk5MM0FGT1AyR1RXUTdRSDhUVC4u',
    },
    modal: {
      title: 'What do you need to submit?',
      descText1:
        'Provide Github address/project link/documentation for selection and testing in accordance with your project type.',
      descText2:
        'A video (about 3 minutes) to demonstrate your project product. Videos must be uploaded to YouTube or Twitter and made public. (if post a tweet, you need @5Degrees__)',
      descText3: 'Email and other personal information',
      startBuilding: 'Start Building',
    },
  },
}
